/**
 * Created by Mikhail Menshenin on 04.03.2025
 */

cleverapps.overrideStyles(cleverapps.styles.VictoryMessageContentView, {
    text: {
        position: [{
            x: { align: "center", dx: 90 },
            y: { align: "center" }
        }, {
            x: { align: "center" },
            y: { align: "center" }
        }, {
            x: { align: "center" },
            y: { align: "center" }
        }]
    },

    animation: {
        position: [{
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 12 }
        }, {
            x: { align: "center", dx: -50 },
            y: { align: "center", dy: 52 }
        }, {
            x: { align: "center", dx: -50 },
            y: { align: "center", dy: 52 }
        }]
    }
});