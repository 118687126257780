/**
 * Created by Mikhail Menshenin on 20.06.2024
 */

var TournamentWindow = CleverappsWindow.extend({
    onWindowLoaded: function (mission) {
        var styles = cleverapps.styles.TournamentWindow;

        this.mission = mission;

        this.wasFinished = !this.mission.isRunning();
        if (this.wasFinished) {
            this.prize = this.mission.getPrize();
        }

        this.content = this.createContent();

        SceneDecors.add(this.content, cleverapps.skins.getSlot("missionWindowDecors"));

        this.mission.onChangeResults = this.createListener(function () {
            var results = this.mission.getResults();
            mission.table.updateRows(results);
        }.bind(this));

        this._super({
            title: {
                text: "Missions." + mission.getName() + ".Title",
                font: cleverapps.skins.getSlot("missionWindowTitleFont")
            },
            name: "missionwindow",
            content: this.content,
            button: {
                width: styles.button.width,
                height: styles.button.height,
                text: this.getButtonText(),
                type: styles.button.type,
                onPressed: this.onButtonPressed.bind(this)
            },
            help: this.wasFinished ? undefined : function () {
                GuideWindow.createMissionRulesWindow(mission);
            },
            styles: styles.window
        });

        var timer = this.createTimer();
        if (timer) {
            timer.setPositionRound(styles.timer);
            this.window.addChild(timer);
        }
    },

    onButtonPressed: function () {
        this.close();

        if (!this.wasFinished) {
            cleverapps.focusManager.distract({
                focus: "MissionPlayButtonPressed",
                action: function (f) {
                    cleverapps.meta.wantsToPlay(f);
                }
            });
        }
    },

    getButtonText: function () {
        if (this.wasFinished) {
            return Messages.get(this.prize ? "ClaimPrize.Button.text" : "OK");
        }

        return Messages.get("Play").toUpperCase();
    },

    createContent: function () {
        var styles = cleverapps.styles.TournamentWindow;

        this.tableView = this.createTable();
        var items = [];

        this.animationBlock = this.createAnimationBlock(this.tableView.width);
        items.push(this.animationBlock);
        items.push(this.tableView);

        return new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });
    },

    createTable: function () {
        var styles = cleverapps.styles.TournamentWindow;
        return new TableView(this.mission.table, {
            id: this.mission.type,
            data: this.mission.getResults(),
            height: styles.table.height,
            rowOptions: {
                dataIcon: MissionWindow.getTableRowIcon(this.mission.type),
                mission: this.mission
            }
        });
    },

    createTimer: function () {
        var styles = cleverapps.styles.TournamentWindow;
        var timeLeft = this.mission.getTimeLeft();
        if (timeLeft > 0) {
            timeLeft = new cleverapps.CountDown(timeLeft, {
                onFinish: function () {
                    if (this.onFinishedSound && typeof this.onFinishedSound === "function") {
                        this.onFinishedSound(true);
                    }
                    this.close();
                }.bind(this)
            });
        }

        var timer = new cleverapps.CountDownView(timeLeft, {
            font: cleverapps.styles.FONTS.MISSION_WINDOW_COUNTDOWN_TEXT || cleverapps.styles.FONTS.WINDOW_TEXT,
            background: {
                frame: styles.timerBackground.frame,
                width: styles.timerBackground.width,
                height: styles.timerBackground.height
            }
        });

        return timer;
    },

    createAnimationBlock: function (tableWidth) {
        var styles = cleverapps.styles.TournamentWindow.desc;

        var bg = cleverapps.UI.createScale9Sprite(bundles.letter_tournament.frames.blue_block_png);
        bg.setContentSize(tableWidth, styles.height);

        var image = new cc.Sprite(bundles.letter_tournament.frames.big_chest_png);
        image.setPositionRound(styles.image);
        bg.addChild(image);

        var text = cleverapps.UI.generateOnlyText("Missions.LetterTournament.InfoText", cleverapps.styles.FONTS.TOURNAMENT_WINDOW_TEXT);
        text.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        text.setWrapWidth(styles.text.wrap);
        text.fitTo(styles.text.width, styles.text.height);
        text.setPositionRound(styles.text);
        bg.addChild(text);

        var block = new cc.Node();
        block.setAnchorPoint(0.5, 0.5);
        block.setContentSize(cleverapps.UI.calculateContentSize(bg));
        block.addChild(bg);

        bg.setAnchorPoint(0.5, 0);
        bg.setPosition(block.width / 2, 0);

        return block;
    },

    onFinishedSound: function (isFinished) {
        if (isFinished && this.mission && !this.mission.finishTimeSoundPlayed && cleverapps.windows.currentWindow() === this) {
            this.mission.finishTimeSoundPlayed = true;
            cleverapps.audio.playSound(bundles.main.urls.mission_finish_effect);
        }
    },

    onShow: function () {
        this._super();

        if (this.onFinishedSound && typeof this.onFinishedSound === "function") {
            this.onFinishedSound(this.wasFinished);
        }
    },

    onClose: function () {
        if (this.mission.runningCompetition) {
            this.mission.runningCompetition.updateShownPlace();
            this.mission.updateMissionIcon();
        }
    },

    listBundles: function () {
        return ["letter_tournament"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    TOURNAMENT_WINDOW_TEXT: {
        size: 28,
        lineHeight: 30,
        color: new cc.Color(28, 101, 189)
    }

});

cleverapps.styles.TournamentWindow = cleverapps.overrideStyles(cleverapps.styles.MissionWindow, {
    window: {
        margin: 46,
        padding: {
            bottom: 50
        }
    },

    button: {
        width: 260,
        height: 100,
        type: cleverapps.styles.UI.Button.Images.button_green
    },

    table: {
        height: 520
    },

    desc: {
        height: 300,
        padding: 20,
        image: {
            x: { align: "left", dx: 40 },
            y: { align: "center", dy: 20 }
        },
        text: {
            x: { align: "right", dx: -30 },
            y: { align: "center" },
            wrap: 390,
            width: 460,
            height: 200
        }
    },

    timer: {
        x: { align: "center" },
        y: { align: "bottom", dy: -55 }
    }
});
