/*
 * Created by Mikhail Menshenin on 10.04.2024
 */

var MedalShineView = cc.Node.extend({
    ctor: function (level, options) {
        this._super();
        this.setVisible(!!level);
        this.setAnchorPoint(0.5, 0.5);

        level = level || 1;
        options = options || {};

        if (options.withShadow) {
            var medalIconShadow = this.medalIconShadow = new cc.Sprite(bundles.medalcollection.frames["medal_shadow_" + (level % 2 ? "odd" : "even")]);
            this.setContentSize2(medalIconShadow.getContentSize());
            medalIconShadow.setPositionRound({
                x: { align: "center" },
                y: { align: "center" }
            });
            this.addChild(medalIconShadow);
        }

        var medalIcon = this.medalIcon = new cc.Sprite(bundles.medalcollection.frames["medal_" + level]);
        this.setContentSize2(medalIcon.getContentSize());
        medalIcon.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        this.addChild(medalIcon);

        var unlockAnimation = this.animation = new cleverapps.Spine(bundles.medalcollection.jsons.unlocked_medal_json);
        unlockAnimation.setScale(options.scale || 1);
        unlockAnimation.setPositionRound({
            x: { align: "center" },
            y: { align: "center" }
        });
        unlockAnimation.setCompleteListener(function () {
            unlockAnimation.runAction(
                new cc.Sequence(
                    new cc.DelayTime(cleverapps.Random.mathChoose(MedalShineView.RANDOM_ANIMATION_INTERVALS)),
                    new cc.CallFunc(function () {
                        unlockAnimation.setAnimation(0, "unlocked_medal", false);
                    })
                )
            );
        });
        this.addChild(unlockAnimation);
        unlockAnimation.setVisible(false);
        unlockAnimation.runAction(
            cc.sequence(
                cc.delayTime(options.starsDelay || 0),
                cc.callFunc(function () {
                    unlockAnimation.setVisible(true);
                    unlockAnimation.setAnimation(0, "unlocked_medal", false);
                })
            )
        );

        if (!level) {
            this.setVisible(false);
        }

        this.runAction(
            cc.sequence(
                cc.delayTime(options.raysDelay || 0),
                cc.callFunc(function () {
                    StandartAnimations.rays(this.medalIcon);
                }.bind(this))
            )
        );
    },

    setLevel: function (level) {
        this.setVisible(true);
        this.medalIconShadow.setSpriteFrame(bundles.medalcollection.frames["medal_shadow_" + (level % 2 ? "odd" : "even")]);
        this.medalIcon.setSpriteFrame(bundles.medalcollection.frames["medal_" + level]);
        this.animation.setAnimation(0, "unlocked_medal", false);
    }
});

MedalShineView.RANDOM_ANIMATION_INTERVALS = [5, 7, 9, 11, 12];
