/**
 * Created by slava on 04.10.2023
 */

var SimpleProgressAltView = cc.Node.extend({
    ctor: function () {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var starCountView = this.starCountView = new SimpleStarCountView();
        this.addChild(starCountView);

        cleverapps.meta.on("moveNext", this.changeCurrent.bind(this), this);
        cleverapps.meta.on("progressChanged", this.updateProgress.bind(this), this);
        cleverapps.meta.getSelectedLocation().on("animateProgressChange", this.updateProgress.bind(this), this);
        cleverapps.meta.getSelectedLocation().on("showForce", this.showForce.bind(this), this);

        this.updateSize();
        this.setupChildren();
    },

    updateSize: function () {
        var styles = cleverapps.styles.SimpleProgressAltView;
        if (this.resolurionMod !== cleverapps.resolution.mode) {
            this.createBg();
            this.createScroll();
        }
        this.resolurionMod = cleverapps.resolution.mode;

        this.setContentSize2(this.scroll.width * this.scroll.scale, this.scroll.height * this.scroll.scale + (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? styles.scroll.vertical.padding : 0));

        this.bgFrame1.removeFromParent();
        this.bgFrame1 = cleverapps.UI.createScale9Sprite(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? bundles.simple.frames.frame_vertical_1 : bundles.simple.frames.frame_1);
        this.bgFrame1.setContentSize2(styles.background.size.frame1);
        this.addChild(this.bgFrame1, -1);

        this.bgFrame2.removeFromParent();
        this.bgFrame2 = cleverapps.UI.createScale9Sprite(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? bundles.simple.frames.frame_vertical_2 : bundles.simple.frames.frame_2);
        this.bgFrame2.setContentSize2(styles.background.size.frame2);
        this.addChild(this.bgFrame2, -1);
    },

    setupChildren: function () {
        this.setPositionRound(cleverapps.styles.SimpleProgressAltView.position);
        this.scroll.setPositionRound(cleverapps.styles.SimpleProgressAltView.scroll.position);
        this.bgFrame1.setPositionRound(cleverapps.styles.SimpleProgressAltView.background.position.frame1);
        this.bgFrame2.setPositionRound(cleverapps.styles.SimpleProgressAltView.background.position.frame2);
        this.starCountView.setPositionRound(cleverapps.styles.SimpleProgressAltView.starCount);
    },

    createBg: function () {
        var styles = cleverapps.styles.SimpleProgressAltView;
        if (this.bgFrame1) {
            this.bgFrame1.removeFromParent();
            this.bgFrame2.removeFromParent();
        }

        var bgFrame1 = this.bgFrame1 = cleverapps.UI.createScale9Sprite(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? bundles.simple.frames.frame_vertical_1 : bundles.simple.frames.frame_1);
        bgFrame1.setContentSize2(styles.background.size.frame1);
        this.addChild(bgFrame1, -1);

        var bgFrame2 = this.bgFrame2 = cleverapps.UI.createScale9Sprite(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? bundles.simple.frames.frame_vertical_2 : bundles.simple.frames.frame_2);
        bgFrame2.setContentSize2(styles.background.size.frame2);
        this.addChild(bgFrame2, -1);
    },

    createIcons: function () {
        this.items = [];

        var totalCount = cleverapps.meta.listAvailableLocations().length;

        for (var i = 0; i < totalCount; i++) {
            var item = this.createItem(i);
            var lineBefore = this.createLine(i);
            var lineAfter = this.createLine(i + 1);

            var layout = new cleverapps.Layout([lineBefore, item, lineAfter], {
                direction: cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL,
                margin: cleverapps.styles.SimpleProgressAltView.line.overlap,
                reversed: cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL
            });
            layout.item = item;
            this.items.push(layout);
        }

        return [this.createEmptyItem(this.items[0].getContentSize())].concat(this.items);
    },

    showAnimation: function (silent) {
        if (silent) {
            this.visible = true;
            this.scale = 1;
            this.opacity = 255;
            return;
        }

        this.setCascadeOpacityEnabledRecursively(true);

        this.runAction(
            new cc.Sequence(
                new cc.Hide(),
                new cc.FadeOut(0),
                new cc.ScaleTo(0, 0.6),
                new cc.Show(),
                new cc.Spawn(
                    new cc.ScaleTo(0.2, 1).easing(cc.easeOut(2)),
                    new cc.FadeIn(0.2).easing(cc.easeOut(2))
                )
            )
        );
    },

    hideAnimation: function (silent) {
        if (silent) {
            this.visible = false;
            return;
        }

        this.setCascadeOpacityEnabledRecursively(true);

        this.runAction(
            new cc.Sequence(
                new cc.Spawn(
                    new cc.ScaleTo(0.1, 0.7).easing(cc.easeIn(2)),
                    new cc.FadeOut(0.1)
                ),
                new cc.Hide()
            )
        );
    },

    createScroll: function () {
        if (this.scroll) {
            this.scroll.removeFromParent();
        }

        var styles = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.styles.SimpleProgressAltView.scroll.vertical : cleverapps.styles.SimpleProgressAltView.scroll.horizontal;

        var direction = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.UI.VERTICAL : cleverapps.UI.HORIZONTAL;
        var reversed = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL;
        var width = styles.width;
        var height = styles.height;
        var items = this.createIcons();

        var scroll = this.scroll = new SnapScrollView(items, {
            direction: direction,
            pagesCount: cleverapps.styles.SimpleProgressAltView.scroll.pagesCount,
            height: height,
            width: width,
            reversed: reversed,
            scrollBarEnabled: false
        });

        scroll.onChangePage = this.updateText.bind(this);

        scroll.getPagesAmount = function () {
            return this.items.length - 1;
        };

        if (styles.scale) {
            scroll.setScale(styles.scale);
        }

        var current = cleverapps.meta.getSelectedLocation().locationId;
        scroll.scrollTo(this.items[current], 0, {
            callback: this.updateText.bind(this, current + 1)
        });

        scroll.onUpdatePositionListener = this.changeItemZoom.bind(this, scroll);
        this.changeItemZoom(scroll);
        this.addChild(scroll);
    },

    changeItemZoom: function (scroll) {
        var zoom = cleverapps.styles.SimpleProgressAltView.scroll.zoom;
        var pos = scroll.getContainerPosition();

        var offset;
        if (scroll.dir === cleverapps.UI.ScrollView.DIR_HORIZONTAL) {
            offset = (pos.x + (scroll.innerContent.width - scroll.pageSize.width) / 2) / scroll.pageSize.width;
        } else {
            offset = (pos.y + (scroll.innerContent.height - scroll.pageSize.height) / 2) / scroll.pageSize.height;
        }

        var lower = cleverapps.clamp(Math.floor(offset), 0, scroll.getPagesAmount() + 1);
        var upper = Math.min(lower + 1, scroll.getPagesAmount() + 1);
        var center = cleverapps.clamp(Math.round(offset), 0, scroll.getPagesAmount() + 1);

        if (scroll.items[center]) {
            scroll.items[center].replaceParentSamePlace(scroll.items[center].parent);
        }

        if (scroll.items[upper] && scroll.items[upper].item && !scroll.items[upper].item.cantScale) {
            scroll.items[upper].item.setScale((offset - lower) * (1 - zoom) + zoom);
        }
        if (scroll.items[lower] && scroll.items[lower].item && !scroll.items[lower].item.cantScale) {
            scroll.items[lower].item.setScale((upper - offset) * (1 - zoom) + zoom);
        }
    },

    createEmptyItem: function (size) {
        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);
        container.setContentSize2(size);
        return container;
    },

    createLine: function (index) {
        var current = cleverapps.meta.getSelectedLocation().locationId;
        var line = new cc.Node();
        var totalCount = cleverapps.meta.listAvailableLocations().length;

        if (index > 0 && index < totalCount) {
            if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                line = cleverapps.UI.createScale9Sprite(index <= current ? bundles.simple.frames.line_bg_vertical_completed : bundles.simple.frames.line_bg_vertical_uncompleted);
            } else {
                line = cleverapps.UI.createScale9Sprite(index <= current ? bundles.simple.frames.line_bg_completed : bundles.simple.frames.line_bg_uncompleted);
            }
        }
        line.setContentSize2(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.styles.SimpleProgressAltView.line.vertical : cleverapps.styles.SimpleProgressAltView.line.horizontal);
        line.setLocalZOrder(-1);
        return line;
    },

    createLineBar: function () {
        var line = cleverapps.UI.createScale9Sprite(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? bundles.simple.frames.line_bg_vertical_completed : bundles.simple.frames.line_bg_completed_bar);

        line.setContentSize2(cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL ? cleverapps.styles.SimpleProgressAltView.line.vertical : cleverapps.styles.SimpleProgressAltView.line.horizontal);
        line.setLocalZOrder(-1);
        return line;
    },

    createIcon: function (index) {
        var current = cleverapps.meta.getSelectedLocation().locationId;
        if (index <= current + 1) {
            var icon = new cc.Sprite(bundles.simple.frames["background_" + index]);

            if (index === current + 1) {
                cleverapps.UI.convertToGrayShape(icon);
            }

            return icon;
        }

        return new cc.Sprite(bundles.simple.frames.lock_png);
    },

    createItem: function (index) {
        var styles = cleverapps.styles.SimpleProgressAltView.scroll;
        var lastLocationId = cleverapps.meta.listAvailableLocations().slice(-1)[0];

        var container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);

        container.progressBar = this.createProgressBar(index);
        container.setContentSize2(container.progressBar);
        container.progressBar.setPosition(container.width / 2, container.height / 2);
        container.addChild(container.progressBar);

        if (index !== lastLocationId) {
            container.icon = this.createIcon(index);
            container.icon.setPosition(container.width / 2, container.height / 2);
            container.addChild(container.icon);
        }

        if (index === lastLocationId) {
            var comingSoonText = cleverapps.UI.generateTTFText("BackgroundsWindow.ComingSoonTitle", cleverapps.styles.FONTS.BACKGROUNDS_WINDOW_NAME_COMING_SOON);
            comingSoonText.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
            comingSoonText.setPosition(container.width / 2 + styles.dx, container.height / 2);
            comingSoonText.setDimensions(container.width - styles.diffX, 0);
            container.addChild(comingSoonText);
        }

        container.setScale(styles.zoom);

        return container;
    },

    createProgressBar: function (index) {
        var current = cleverapps.meta.getSelectedLocation().locationId;
        var progressBar = new cleverapps.Spine(bundles.simple.jsons.progress_bar_animation);

        var animation = "idle completed";
        if (index === current) {
            animation = "idle_" + this.getCurrentAnimationNumber();
        }
        if (index > current) {
            animation = "idle closed";
        }

        progressBar.setAnimation(0, animation, false);
        return progressBar;
    },

    updateProgress: function (addedStars, callback) {
        var current = cleverapps.meta.getSelectedLocation().locationId;
        var lastLocationId = cleverapps.meta.listAvailableLocations().slice(-1)[0];

        var animationNumber = this.getCurrentAnimationNumber();

        var progressBar = this.items[current].item.progressBar;

        if (animationNumber === 0 || (current === lastLocationId - 1 && animationNumber === 100)) {
            progressBar.setAnimation(0, "idle_" + animationNumber, false);
        } else {
            progressBar.setAnimationAndIdleAfter("animation_" + animationNumber, "idle_" + animationNumber);
        }

        progressBar.setCompleteListener(function () {
            cleverapps.focusManager.onFocusLostListenerPlacements();
            callback && callback();
        });
    },

    getCurrentAnimationNumber: function () {
        return Math.floor((cleverapps.meta.getSelectedLocation().getCurrentPercent()) / 5) * 5;
    },

    changeCurrent: function (f) {
        var current = cleverapps.meta.getSelectedLocation().locationId;

        var previousItem = this.items[current - 1];
        var nextItem = this.items[current + 1];
        var currentItem = this.items[current];

        if (current === this.items.length) {
            nextItem = false;
        }

        this.scroll.runAction(
            new cc.Sequence(
                new cc.CallFunc(function () {
                    this.scroll.scrollTo(previousItem, 1, {
                        callback: this.updateText.bind(this, current)
                    });
                }.bind(this)),
                new cc.DelayTime(1),

                new cc.CallFunc(function () {
                    var animation = new cleverapps.Spine(bundles.simple.jsons.circle_particles_animation);
                    animation.setAnimation(0, "animation", false);
                    animation.setPosition(previousItem.item.icon.width / 2, previousItem.item.icon.height / 2);
                    cleverapps.UI.fitToBox(animation, previousItem.item.icon);
                    animation.setCompleteListenerRemove();

                    previousItem.item.icon.addChild(animation, -1);
                    previousItem.item.icon.runAction(StandartAnimations.pulse(previousItem.item.icon, {}));

                    cleverapps.audio.playSound(bundles.main.urls.simple_next_stage);
                }),
                new cc.DelayTime(1.5),

                new cc.CallFunc(function () {
                    previousItem.item.runAction(
                        new cc.Sequence(
                            new cc.ScaleTo(0.23, 1.1),
                            new cc.ScaleTo(0.33, cleverapps.styles.SimpleProgressAltView.scroll.zoom).easing(cc.easeInOut(3))
                        )
                    );
                    previousItem.item.progressBar.setAnimation(0, "completion animation", false);
                    this.starCountView.runAction(new cc.FadeOut(0.3));
                }.bind(this)),
                new cc.DelayTime(0.6),

                new cc.CallFunc(function () {
                    previousItem.item.setScale(cleverapps.styles.SimpleProgressAltView.scroll.zoom);
                    previousItem.item.cantScale = true;
                    previousItem.item.progressBar.setAnimation(0, "completed mark", false);
                }),
                new cc.DelayTime(0.2),

                new cc.CallFunc(function () {
                    if (nextItem) {
                        nextItem.item.icon.removeFromParent();
                        nextItem.item.icon = this.createIcon(current + 1);
                        nextItem.item.icon.setPosition(nextItem.item.width / 2, nextItem.item.height / 2);
                        nextItem.item.addChild(nextItem.item.icon);
                        cleverapps.UI.convertToGrayShape(nextItem.item.icon);
                    }
                    this.scroll.scrollTo(currentItem, 1.5, {
                        callback: this.updateText.bind(this, current + 1)
                    });

                    var line = this.createLineBar(current);
                    previousItem.addChild(line);

                    if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                        line.setPositionRound(cleverapps.styles.SimpleProgressAltView.line.vertical.next);
                        line.runAction(new cc.MoveBy(1.5, 0, cleverapps.styles.SimpleProgressAltView.line.vertical.dy));
                    } else {
                        line.setPositionRound(cleverapps.styles.SimpleProgressAltView.line.horizontal.next);
                        line.runAction(new cc.MoveBy(1.5, cleverapps.styles.SimpleProgressAltView.line.horizontal.dx, 0));
                    }
                }.bind(this)),
                new cc.DelayTime(0.5),

                new cc.CallFunc(function () {
                    if (currentItem.item) {
                        cleverapps.UI.convertToGrayShape(currentItem.item.icon, true);
                    }
                    currentItem.item.progressBar.setAnimation(0, "opening", false);
                }),
                new cc.DelayTime(1),

                new cc.CallFunc(function () {
                    previousItem.item.cantScale = false;
                    currentItem.item.progressBar.setAnimation(0, "current appear", false);
                    cleverapps.meta.trigger("progressChanged", true);
                    this.starCountView.runAction(new cc.FadeIn(0.3));
                    this.starCountView.setPositionRound(cleverapps.styles.SimpleProgressAltView.starCount);
                }.bind(this)),
                new cc.DelayTime(1),

                new cc.CallFunc(function () {
                    if (this.dialogue) {
                        this.dialogue.removeFromParent();
                    }
                    cleverapps.audio.playSound(bundles.main.urls.simple_transition);
                    f();
                }.bind(this))
            )
        );
    },

    forceAnimation: function () {
        var dialogue = this.dialogue = new MinimalDialogue({
            text: "Simple.Force",
            rects: [this.getSceneBoundingBox()]
        });
        cleverapps.scenes.getRunningScene().addChild(dialogue);
        dialogue.display();
    },

    showForce: function () {
        cleverapps.forces.create(this, Forces.SIMPLE);

        this.forceAnimation();
    },

    updateText: function (index) {
        var msg = "background_name_" + Math.max(0, index - 1);
        var newText = cleverapps.UI.generateOnlyText(msg, cleverapps.styles.FONTS.BACKGROUND_NAME_TEXT);
        newText.fitTo(cleverapps.styles.SimpleProgressAltView.text.width);
        newText.setPositionRound(cleverapps.styles.SimpleProgressAltView.text.position);
        this.addChild(newText);

        if (this.text && this.text.string !== newText.string) {
            newText.stopAllActions();
            newText.setVisible(false);
            newText.runAction(new cc.Sequence(
                new cc.CallFunc(function () {
                    this.text.runAction(StandartAnimations.disappear(this.text)).setFinalize(function () {
                        this.text.removeFromParent();
                    }.bind(this));
                }.bind(this)),
                StandartAnimations.showUp(newText),
                new cc.CallFunc(function () {
                    this.text = newText;
                }.bind(this))
            ));
        } else {
            if (this.text) {
                this.text.removeFromParent();
            }
            this.text = newText;
        }

        cleverapps.audio.playSound(bundles.main.urls.slide);
    },

    onShow: function () {
        cleverapps.audio.playSound(bundles.main.urls.open_main_scene);
    }
});

ProgressView = SimpleProgressAltView;

cleverapps.UI.convertToGrayShape = cleverapps.extendFunc(cleverapps.UI.convertToGrayShape, function (node, restore) {
    node.setColor(cleverapps.styles.COLORS.DARK_GRAY);
    node.setOpacity(125);

    if (restore) {
        node.setColor(cleverapps.styles.COLORS.WHITE);
        node.setOpacity(255);
    }
});

cleverapps.styles.SimpleProgressAltView = {
    position: [
        {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 15 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 45 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 45 }
        }
    ],

    line: {
        overlap: -20,
        vertical: {
            height: 80,
            width: 40,
            next: {
                x: { align: "center" },
                y: { align: "top", dy: -100 }
            },
            dy: 100
        },
        horizontal: {
            height: 50,
            width: 100,
            next: {
                x: { align: "right", dx: -100 },
                y: { align: "center", dy: 1 }
            },
            dx: 100
        }
    },

    starCount: [{
        x: { align: "center" },
        y: { align: "top", dy: -20 }
    }, {
        x: { align: "center" },
        y: { align: "top", dy: -28 }
    }, {
        x: { align: "center" },
        y: { align: "top", dy: -28 }
    }],

    text: {
        width: 250,
        position: [{
            x: { align: "center" },
            y: { align: "bottom", dy: 20 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: -60 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: -60 }
        }]
    },

    background: {
        position: {
            frame1: [
                {
                    x: { align: "center", dx: 1 },
                    y: { align: "center", dy: 45 }
                }, {
                    x: { align: "center", dx: 1 },
                    y: { align: "center", dy: 0 }
                }, {
                    x: { align: "center", dx: 1 },
                    y: { align: "center", dy: 0 }
                }
            ],
            frame2: [
                {
                    x: { align: "center", dx: 1 },
                    y: { align: "center", dy: -392 }
                }, {
                    x: { align: "center", dx: 1 },
                    y: { align: "center", dy: -335 }
                }, {
                    x: { align: "center", dx: 1 },
                    y: { align: "center", dy: -335 }
                }
            ]
        },
        size: {
            frame1: [
                {
                    width: 365,
                    height: 795
                }, {
                    width: 1200,
                    height: 600
                }, {
                    width: 1200,
                    height: 600
                }
            ],
            frame2: [
                {
                    width: 290,
                    height: 79
                }, {
                    width: 434,
                    height: 70
                }, {
                    width: 434,
                    height: 70
                }
            ]
        }
    },

    scroll: {
        position: [{
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 20 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -58 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: -58 }
        }],
        pagesCount: 3,
        zoom: 0.6,
        diffX: 20,
        dx: 8,

        vertical: {
            scale: 0.6,
            height: 1100,
            width: 610,
            padding: 210
        },

        horizontal: {
            height: 600,
            width: 1200
        }
    }
};
