/**
 * Created by Mikhail Menshenin on 18.02.2025
 */

SimpleLocation.prototype.amountLevelStars = cleverapps.extendFunc(SimpleLocation.prototype.amountLevelStars, function (level) {
    if (cleverapps.config.name === "yatzy") {
        var game = Game.currentGame;
        var score = game.score.points;
        if (score > 200) {
            return 3;
        }
        if (score > 150) {
            return 2;
        }
        if (score > 100) {
            return 1;
        }
        return 0;
    }

    return this._super(level);
});

SimpleLocation.prototype.getCurrentPercent = function () {
    var total = this.getRequiredProgress();
    var percent = cleverapps.meta.getSelectedLocation().getProgress() / total * 100;
    return (percent < 100) ? percent : 100;
};

SimpleLocation.prototype.onOpen = function (f) {
    var scene = cleverapps.scenes.getRunningScene();
    cleverapps.focusManager.compound(f, [
        function (f) {
            var bundles = this.calcBundles();
            if (bundles) {
                cleverapps.bundleLoader.loadBundles(bundles);
            }
            connector.social.markAchievement({});
            cleverapps.eventLogger.logEvent(cleverapps.EVENTS.SIMPLE_METHA_LEVELUP);
            f();
        }.bind(this),
        function (f) {
            cleverapps.meta.trigger("moveNext", f);
        },
        function (f) {
            scene.moveNextBg(f);
        }
    ]);
};
