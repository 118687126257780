/**
 * Created by Mikhail Menshenin on 18.02.2025
 */

Placements.ENTRIES["simpleMoveNext"].action = function () {
    cleverapps.focusManager.display({
        focus: "simpleMoveNextLocation",
        control: ["progress_view"],
        actions: [
            function (f) {
                if (cleverapps.meta.getSelectedLocation().isForceAvailable()) {
                    cleverapps.meta.getSelectedLocation().showForce();
                }
                f();
            },
            function (f) {
                if (cleverapps.meta.getSelectedLocation().getPendingProgress() > 0) {
                    cleverapps.meta.getSelectedLocation().animateProgressChange(f);
                } else {
                    f();
                }
            },
            function (f) {
                cleverapps.meta.moveNextLocation();
                cleverapps.meta.getSelectedLocation().onOpen(f);
            },
            function (f) {
                if (cleverapps.playButton && cleverapps.playButton.passedLevel) {
                    cleverapps.playButton.animatePassLevel();
                }
                f();
            }
        ]
    });
};
