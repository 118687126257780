/**
 * Created by Slava on 24.07.2024
 */

var ProlongationTutorial = function () {
    return new TutorialScenario("Prolongation", {
        onStart: function () {
            Game.currentGame.resetDices();
        },
        
        listSteps: ProlongationTutorial.listSteps,

        isAvailable: ProlongationTutorial.isAvailable
    });
};

ProlongationTutorial.listSteps = function () {
    var slot = ProlongationTutorial.getBestSlot();
    var selectedDices = ProlongationTutorial.getSelectedDices(slot);
    var roll = ProlongationTutorial.getRoll(slot, selectedDices);
    var steps = [];

    ProlongationTutorial.STEPS.forEach(function (data) {
        data = cleverapps.clone(data);
        if (data.chooseDice !== undefined && selectedDices[data.chooseDice] === undefined) {
            return;
        }

        if (data.chooseDice !== undefined) {
            data.dice = selectedDices[data.chooseDice].id;
            delete data.chooseDice;
        }

        if (data.roll) {
            data.roll = roll;
        }

        if (data.slot) {
            data.slot = slot;
        }

        steps.push(YatzyTutorialHelper.createStep(data));
    });

    return steps;
};

ProlongationTutorial.getSelectedDices = function (slot) {
    var yatzy = Game.currentGame;
    var selectedDices = [];

    if (slot === Slot.YATZY) {
        selectedDices = YatzyTutorialHelper.find3DicesWithSameScore(true);
    } else if (slot === Slot.FULL_HOUSE) {
        var diceCount = yatzy.getDiceCount();
        yatzy.dices.forEach(function (dice) {
            if (diceCount[dice.score] === 2) {
                selectedDices.push(dice);
            }
        });
    } else if (slot === Slot.LARGE_STRAIGHT) {
        var sequence = yatzy.getSequences().find(function (seq) {
            return seq.length === 4;
        });
        var used = [];

        yatzy.dices.forEach(function (dice) {
            if (sequence.includes(dice.score) && !used[dice.score]) {
                selectedDices.push(dice);
                used[dice.score] = true;
            }
        });
    }

    return selectedDices;
};

ProlongationTutorial.getRoll = function (slot, selectedDices) {
    var score = selectedDices[0].score;
    if (slot === Slot.LARGE_STRAIGHT) {
        var minScore = Math.min.apply(null, selectedDices.map(function (dice) {
            return dice.score; 
        }));
        score = minScore < 3 ? minScore + 4 : 2;
    }

    return Game.currentGame.dices.map(function (dice) {
        if (selectedDices.includes(dice)) {
            return dice.score;
        }

        return score;
    });
};

ProlongationTutorial.isAvailable = function () {
    var yatzy = Game.currentGame;
    if (cleverapps.forces.isShown(Forces.FREE_PROLONGATION_FORCE.id)
        || !yatzy
        || !yatzy.noRollsLeft()
        || !levels.user.checkAvailable(cleverapps.Availables.FREE_PROLONGATION_FORCE)
        || levels.user.getFloatLevel() > Prolongation.AVAILABLE_THRESHOLD
    ) {
        return false; 
    }

    return ProlongationTutorial.getBestSlot() !== undefined;
};

ProlongationTutorial.getBestSlot = function () {
    var yatzy = Game.currentGame;

    var slot = yatzy.slots[Slot.YATZY];
    if (!slot.used && slot.score === 0) {
        if (YatzyTutorialHelper.find3DicesWithSameScore(true)) {
            return Slot.YATZY;
        }
    }

    slot = yatzy.slots[Slot.FULL_HOUSE];
    if (!slot.used && YatzyTutorialHelper.hasTwoPairs()) {
        return Slot.FULL_HOUSE;
    }

    slot = yatzy.slots[Slot.LARGE_STRAIGHT];
    if (!slot.used && slot.score === 0) {
        var sequence = yatzy.getSequences().find(function (seq) {
            return seq.length === 4;
        });

        if (sequence) {
            return Slot.LARGE_STRAIGHT;
        }
    }
};

ProlongationTutorial.STEPS = [
    {
        chooseDice: 0,
        force: {
            text: "tutorial_prolongation_0"
        }
    },
    {
        chooseDice: 1,
        force: {
            text: ""
        }
    },
    {
        chooseDice: 2,
        force: {
            text: ""
        }
    },
    {
        chooseDice: 3,
        force: {
            text: ""
        }
    },
    {
        roll: true,
        force: {
            text: "tutorial_prolongation_1"
        }
    },
    {
        slot: true,
        force: {
            id: Forces.FREE_PROLONGATION_FORCE.id,
            text: "tutorial_prolongation_2"
        }
    }
];