/**
 * Created by Slava on 08.09.2023
 */

var BasicsTutorial = function () {
    return new TutorialScenario(TutorialScenario.BASIC_TUTORIAL_NAME, {
        onStart: function () {
            Game.currentGame.hintSlots = [];
        },
        onFinish: function () {
            Game.currentGame.hintSlots = undefined;
        },

        listSteps: BasicsTutorial.listSteps,

        isAvailable: BasicsTutorial.isAvailable
    });
};

BasicsTutorial.isAvailable = function () {
    var game = Game.currentGame;
    return game && game.introPlaying && game.levelWithTutorial();
};

BasicsTutorial.listSteps = function () {
    var scene = cleverapps.scenes.getRunningScene();
    var highlights = [scene.rollButton, scene.dicesView, scene.movingNode];

    return BasicsTutorial.STEPS.map(function (data) {
        data = cleverapps.clone(data);
        if (data.column1Highlight === true) {
            highlights.push(scene.yatzyField.column1);
            highlights.push(scene.resultsBar);
        }

        if (data.column2Highlight === true) {
            highlights.push(scene.yatzyField);
        }

        data.highlights = highlights.slice();

        return YatzyTutorialHelper.createStep(data);
    });
};

BasicsTutorial.STEPS = [
    {
        roll: [2, 5, 3, 5, 1],
        importantNodes: [],
        force: {
            text: "tutorial_1_1"
        }
    },
    {
        dice: 1,
        force: {
            text: "tutorial_1_2"
        }
    },
    {
        dice: 3,
        force: {
            text: "tutorial_1_2"
        }
    },
    {
        roll: [1, 0, 2, 0, 5],
        force: {
            text: "tutorial_1_3"
        }
    },
    {
        dice: 4,
        force: {
            text: "tutorial_1_4"
        }
    },
    {
        roll: [5, 0, 1, 0, 0],
        force: {
            text: "tutorial_1_5"
        }
    },
    {
        slot: Slot.FIVES,
        column1Highlight: true,
        force: {
            text: "tutorial_1_6"
        }
    },
    {
        roll: [6, 2, 4, 5, 6],
        force: {
            text: "",
            pointer: false
        }
    },
    {
        dice: 0,
        force: {
            text: ""
        }
    },
    {
        dice: 4,
        force: {
            text: ""
        }
    },
    {
        roll: [6, 6, 3, 1, 6],
        force: {
            text: ""
        }
    },
    {
        dice: 1,
        force: {
            text: ""
        }
    },
    {
        roll: [6, 6, 2, 6, 6],
        force: {
            text: ""
        }
    },
    {
        slot: Slot.SIXES,
        force: {
            text: "tutorial_5_2",
            position: [
                { x: { align: "left" }, y: { align: "center", dy: 45 } },
                { x: { align: "left" }, y: { align: "center", dy: -50 } },
                { x: { align: "left" }, y: { align: "center", dy: -150 } }
            ]
        }
    },
    {
        roll: [3, 3, 4, 5, 1],
        force: {
            text: "",
            pointer: false
        }
    },
    {
        dice: 0,
        force: {
            text: ""
        }
    },
    {
        dice: 1,
        force: {
            text: ""
        }
    },
    {
        roll: [3, 3, 5, 2, 1],
        force: {
            text: ""
        }
    },
    {
        roll: [3, 3, 1, 3, 5],
        force: {
            text: ""
        }
    },
    {
        slot: Slot.THREES,
        force: {
            text: "tutorial_6_1",
            position: {
                x: { align: "left" },
                y: { align: "center", dy: 300 }
            }
        }
    },
    {
        roll: [1, 2, 3, 1, 6],
        force: {
            text: "",
            pointer: false
        }
    },
    {
        dice: 0,
        column2Highlight: true,
        force: {
            text: "tutorial_4_1"
        }
    },
    {
        dice: 1,
        force: {
            text: ""
        }
    },
    {
        dice: 2,
        force: {
            text: ""
        }
    },
    {
        roll: [1, 2, 3, 4, 2],
        force: {
            text: ""
        }
    },
    {
        dice: 3,
        force: {
            text: "tutorial_4_2"
        }
    },
    {
        roll: [1, 2, 3, 4, 5],
        force: {
            text: ""
        }
    },
    {
        slot: Slot.LARGE_STRAIGHT,
        force: {
            text: "tutorial_4_3"
        }
    }
];
