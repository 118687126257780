/**
 * Created by olga on 13.12.2024
 */

YatzyTutorialHelper = {};

YatzyTutorialHelper.createStep = function (data) {
    if (data.roll !== undefined) {
        return YatzyTutorialHelper.createRollStep(data);
    }
    if (data.dice !== undefined) {
        return YatzyTutorialHelper.createDiceStep(data);
    }

    if (data.slot !== undefined) {
        return YatzyTutorialHelper.createSlotStep(data);
    }
};

YatzyTutorialHelper.createRollStep = function (data) {
    var scene = cleverapps.scenes.getRunningScene();
    var force = data.force;

    var options = {
        onStart: function () {
            var highlights = data.highlights || YatzyTutorialHelper.getHighlights();
            var importantNodes = data.importantNodes || YatzyTutorialHelper.getImportantNodes();

            Game.currentGame.setSuggestedRoll(data.roll);

            force.finger = false;
            force.pointer = true;
            this.force = cleverapps.forces.create(scene.rollButton, force, {
                highlights: highlights,
                importantNodes: importantNodes,
                pointerDirection: PointerView.DIRECTIONS.DOWN
            });
            cleverapps.tutorial.on("roll", this.finish.bind(this), this);
        },

        onFinish: function () {
            this.force.hideForceHints();
        }
    };

    return new TutorialStep(options);
};

YatzyTutorialHelper.createSlotStep = function (data) {
    var scene = cleverapps.scenes.getRunningScene();
    var force = data.force;

    var options = {
        onStart: function () {
            var highlights = data.highlights || YatzyTutorialHelper.getHighlights();
            var importantNodes = data.importantNodes || YatzyTutorialHelper.getImportantNodes();

            if (Game.currentGame.hintSlots) {
                Game.currentGame.hintSlots.push(data.slot);
            }

            var yatzyPlayed = Game.currentGame.slots[Slot.YATZY].used;
            var score = Game.currentGame.slots[data.slot].calcScore(yatzyPlayed);
            Game.currentGame.slots[data.slot].setScore(score);
            scene.yatzyField.slots[data.slot].hintScore();
            scene.yatzyField.slots[data.slot].wiggleOn();
            cleverapps.tooltipManager.remove(scene.yatzyField.slots[data.slot].icon);
            Game.currentGame.highlightCombo();

            force.finger = false;
            force.pointer = true;
            this.force = cleverapps.forces.create(scene.yatzyField.slots[data.slot], force, {
                finger: false,
                highlights: highlights,
                importantNodes: importantNodes,
                pointerDirection: data.slot < 6 ? PointerView.DIRECTIONS.LEFT : PointerView.DIRECTIONS.RIGHT
            });

            cleverapps.tutorial.on("slot", this.finish.bind(this), this);
        },

        onFinish: function () {
            this.force.hideForceHints();
            scene.yatzyField.slots[data.slot].wiggleOff();
        }
    };

    return new TutorialStep(options);
};

YatzyTutorialHelper.createDiceStep = function (data) {
    var force = data.force;
    var dice = cleverapps.aims.getTarget("dice" + data.dice);

    var options = {
        onStart: function () {
            var highlights = data.highlights || YatzyTutorialHelper.getHighlights();
            var importantNodes = data.importantNodes || YatzyTutorialHelper.getImportantNodes();
            dice.wiggleOn();

            force.finger = false;
            force.pointer = true;
            this.force = cleverapps.forces.create(dice, force, {
                finger: false,
                highlights: highlights,
                importantNodes: importantNodes,
                pointerDirection: PointerView.DIRECTIONS.DOWN
            });

            cleverapps.tutorial.on("dice", this.finish.bind(this), this);
        },

        onFinish: function () {
            this.force.hideForceHints();
            dice.wiggleOff();
        }
    };

    return new TutorialStep(options);
};

YatzyTutorialHelper.getHighlights = function () {
    var scene = cleverapps.scenes.getRunningScene();
    return [scene.dicesView, scene.yatzyField, scene.rollButton, cleverapps.scenes.getMovingNode()];
};

YatzyTutorialHelper.getImportantNodes = function () {
    var scene = cleverapps.scenes.getRunningScene();
    return [scene.dicesView, scene.yatzyField, scene.rollButton];
};

YatzyTutorialHelper.find3DicesWithSameScore = function (allowGreater) {
    var yatzy = Game.currentGame;
    var diceCount = yatzy.getDiceCount();
    var score;
    diceCount.forEach(function (element, index) {
        if (element === 3 || allowGreater && element > 3) {
            score = index;
        }
    });

    if (score) {
        var dicesWithSameScore = yatzy.dices.filter(function (dice) {
            return dice.score === score;
        });
        if (dicesWithSameScore.length) {
            return dicesWithSameScore;
        }
    }
};

YatzyTutorialHelper.hasTwoPairs = function () {
    var diceCount = Game.currentGame.getDiceCount();
    return diceCount.filter(function (count) {
        return count === 2;
    }).length === 2;
};
