/**
 * Created by Mikhail Menshenin on 17.06.2024
 */

var ComboTutorial = function () {
    return new TutorialScenario("YatzyCombo", {
        listSteps: ComboTutorial.listSteps,
        isAvailable: ComboTutorial.isAvailable
    });
};

ComboTutorial.listSteps = function () {
    var dices = Game.currentGame.dices;
    var steps = [];

    var selectedDices = YatzyTutorialHelper.find3DicesWithSameScore();
    for (var i = 0; i < dices.length; i++) {
        var dice = dices[i];
        if (!selectedDices.includes(dice)) {
            selectedDices.push(dice);
        }
    }
    var selectedScore = selectedDices[0].score;

    ComboTutorial.STEPS.forEach(function (data) {
        data = cleverapps.clone(data);
        if (data.chooseDice !== undefined) {
            data.dice = selectedDices[data.chooseDice].id;
            delete data.chooseDice;
        }

        if (data.rollFirst) {
            var changed = false;
            data.roll = dices.map(function (dice) {
                if (dice.score !== selectedScore && !changed) {
                    changed = true;
                    return selectedScore;
                }

                return dice.score;
            });
        }

        if (data.rollSecond) {
            data.roll = dices.map(function () {
                return selectedScore;
            });
        }

        steps.push(YatzyTutorialHelper.createStep(data));
    });

    return steps;
};

ComboTutorial.isAvailable = function () {
    if (cleverapps.forces.isShown(Forces.YATZY_COMBO.id)) {
        return false;
    }

    var yatzy = Game.currentGame;

    if (!yatzy || yatzy.rolls < 2 || yatzy.fullRolls()) {
        return false;
    }

    if (!levels.user.checkAvailable(cleverapps.Availables.YATZY_COMBO)) {
        return false;
    }

    var yatzySlot = yatzy.slots[Slot.YATZY];
    if (yatzySlot.used) {
        return false;
    }

    return YatzyTutorialHelper.find3DicesWithSameScore();
};

delete Forces.FOG_EXPLORE;
Forces.YATZY_COMBO = {
    id: 13,
    text: "tutorial_yatzy_6"
};

ComboTutorial.STEPS = [
    {
        chooseDice: 0,
        force: {
            text: "tutorial_yatzy_0"
        }
    },
    {
        chooseDice: 1,
        force: {
            text: "tutorial_yatzy_1"
        }
    },
    {
        chooseDice: 2,
        force: {
            text: ""
        }
    },
    {
        rollFirst: true,
        force: {
            text: "tutorial_yatzy_3"
        }
    },
    {
        chooseDice: 3,
        force: {
            text: "tutorial_yatzy_4",
            delay: 1
        }
    },
    {
        rollSecond: true,
        force: {
            text: ""
        }
    },
    {
        slot: Slot.YATZY,
        force: Forces.YATZY_COMBO
    }
];
